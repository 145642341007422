<script setup>
import {ref, toRaw, computed} from 'vue'
import { buildFormState } from '@/utils/form'
import { mailbackShippinAccountsSchema } from '@/validation_schemas/portal/policies/return_methods'
import { MAIL_CARRIERS, UPS_SERVICE_LEVELS, FEDEX_SERVICE_LEVELS, UPS_MAIL_INNOVATIONS } from '@/constants'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'

const props = defineProps({
  items: {
    type: Array,
    default() {
      return []
    }
  },
  disabled: Boolean
})

const emit = defineEmits(['add', 'update', 'remove'])

const fieldsMapping = {
  mailCarrierName: 'mail_carrier_name',
  mailCarrierTitle: 'mail_carrier_title',
  serviceLevel: 'service_level',
  carrierID: 'carrier_id',
  warehouseID: 'warehouse_id',
  returnsService: 'returns_service_enabled',
  usTerritoryFallback: 'us_territory_fallback_enabled',
  internationalEnabled: 'international_enabled'
}

const mailCarrierOptions = [
  { label: 'UPS', value: MAIL_CARRIERS.UPS },
  { label: 'FedEx', value: MAIL_CARRIERS.FEDEX }
]

function getServiceLevelOptions(carrier) {
  const carrierServiceLevels = {
    [MAIL_CARRIERS.UPS]: UPS_SERVICE_LEVELS,
    [MAIL_CARRIERS.FEDEX]: FEDEX_SERVICE_LEVELS
  }

  let serviceLevels = carrierServiceLevels[carrier] || {}

  return Object.entries(serviceLevels).flatMap(([k, v]) => [{label: k, value: v}]);
}

const serviceLevelOptions = computed(() => {
  const carrier = addForm.value.fields.mailCarrierName.inputValue

  return getServiceLevelOptions(carrier)
})

const usTerritorySupported = computed( () => {
  return serviceLevelSupportsFallback(addForm.value.fields.serviceLevel.inputValue)
})

function serviceLevelSupportsFallback(service_level) {
  return service_level === UPS_MAIL_INNOVATIONS
}

function onChangeReturnService(event, index) {
  const updatedItem = {...props.items[index], returns_service_enabled: event.target.checked}

  emit('update', index, updatedItem)
}

function onChangeUSTerritoryFallback(event, index) {
  const updatedItem = {...props.items[index], us_territory_fallback_enabled: event.target.checked}

  emit('update', index, updatedItem)
}

function onChangeInternationalEnabled(event, index) {
  const updatedItem = {...props.items[index], international_enabled: event.target.checked}

  emit('update', index, updatedItem)
}

function onClickRemove(index) {
  emit('remove', index)
}

const addForm = ref(buildFormState({
  validationSchema: mailbackShippinAccountsSchema,
  initialValues: {},
  fieldsMapping
}))

function onClickAdd() {
  const { values, validate, reset } = addForm.value

  if (validate()) {
    emit('add', toRaw(values))
    reset()
  }
}
</script>

<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th>CARRIER *</th>
      <th>CUSTOMER-FACING<br /> CARRIER NAME *</th>
      <th>SERVICE LEVEL *</th>
      <th>CARRIER ID *</th>
      <th>WAREHOUSE ID</th>
      <th>RETURNS SERVICE</th>
      <th>U.S. TERR. & FALL-BACK ONLY</th>
      <th>INTERNATIONAL</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ item.mail_carrier_name }}</td>
        <td>{{ item.mail_carrier_title }}</td>
        <td>{{ item.service_level }}</td>
        <td>{{ item.carrier_id }}</td>
        <td>{{ item.warehouse_id }}</td>
        <td>
          <div class="form-check">
            <input
              type="checkbox"
              name="returns-service"
              class="form-check-input"
              :checked="item.returns_service_enabled"
              :disabled="disabled"
              @change="onChangeReturnService($event, index)"
            >
          </div>
        </td>
        <td>
          <div class="form-check">
            <input
              type="checkbox"
              name="us-terr-fallback"
              class="form-check-input"
              :checked="item.us_territory_fallback_enabled"
              :disabled="disabled || !serviceLevelSupportsFallback(item.service_level)"
              @change="onChangeUSTerritoryFallback($event, index)"
            >
          </div>
        </td>
        <td>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              :checked="item.international_enabled"
              :disabled="disabled"
              @change="onChangeInternationalEnabled($event, index)"
            >
          </div>
        </td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-primary-outline border-0"
            @click="onClickRemove(index)"
            :disabled="disabled"
          >
            <BootstrapIcon name="bs-icon-x-lg" />
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <FormFieldSelect
            class="form-select"
            :fieldModel="addForm.fields.mailCarrierName"
            :disabled="disabled"
          >
            <option :value="null">Carrier</option>
            <option
              v-for="(option, index) in mailCarrierOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="addForm.fields.mailCarrierName" />
        </td>
        <td>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="addForm.fields.mailCarrierTitle"
            :disabled="disabled"
          />
          <FormFieldError :fieldModel="addForm.fields.mailCarrierTitle" />
        </td>
        <td>
          <FormFieldSelect
            class="form-select"
            :fieldModel="addForm.fields.serviceLevel"
            :disabled="disabled"
          >
            <option :value="null">ServiceLevel</option>
            <option
              v-for="(option, index) in serviceLevelOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="addForm.fields.serviceLevel" />
        </td>
        <td>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="addForm.fields.carrierID"
            :disabled="disabled"
          />
          <FormFieldError :fieldModel="addForm.fields.carrierID" />
        </td>
        <td>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="addForm.fields.warehouseID"
            :disabled="disabled"
          />
          <FormFieldError :fieldModel="addForm.fields.warehouseID" />
        </td>
        <td>
          <div class="form-check">
            <FormFieldCheckbox
              class="form-check-input"
              :fieldModel="addForm.fields.returnsService"
              :disabled="disabled"
            />
          </div>
        </td>
        <td>
          <div class="form-check">
            <FormFieldCheckbox
              class="form-check-input"
              :fieldModel="addForm.fields.usTerritoryFallback"
              :disabled="disabled || !usTerritorySupported"
            />
          </div>
        </td>
        <td>
          <div class="form-check">
            <FormFieldCheckbox
              class="form-check-input"
              :fieldModel="addForm.fields.internationalEnabled"
              :disabled="disabled"
            />
          </div>
        </td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-outline-primary"
            :disabled="disabled || !addForm.valid"
            @click="onClickAdd"
          >
            ADD
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<style lang="scss" scoped>
  .btn {
    min-width: 50px;
  }
</style>
